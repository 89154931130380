// src/components/Navbar.js
import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navbarRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMobileMenuOpen &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target)
      ) {
        closeMobileMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <nav className="navbar" ref={navbarRef}>
      <div className="navbar-logo">
        <NavLink to="/" onClick={closeMobileMenu}>
          Asa Sprow
        </NavLink>
      </div>
      <div
        className={`navbar-links ${isMobileMenuOpen ? 'active' : ''}`}
        id="navbar-links"
      >
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
          onClick={closeMobileMenu}
        >
          Home
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
          onClick={closeMobileMenu}
        >
          About Me
        </NavLink>
        <NavLink
          to="/experience"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
          onClick={closeMobileMenu}
        >
          Experience
        </NavLink>
        <NavLink
          to="/projects"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
          onClick={closeMobileMenu}
        >
          Projects
        </NavLink>
      </div>
      <div
        className="navbar-toggle"
        onClick={toggleMobileMenu}
        aria-label="Toggle navigation menu"
        aria-controls="navbar-links"
        aria-expanded={isMobileMenuOpen}
      >
        <FontAwesomeIcon
          icon={isMobileMenuOpen ? faTimes : faBars}
          size="2x"
        />
      </div>
    </nav>
  );
}

export default Navbar;
