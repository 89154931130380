import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faReadme, faPython } from '@fortawesome/free-brands-svg-icons';
import SEOHead from '../../components/SEOHead';

function CoreCT() {
  return (
    <section className="experience-container">
      <SEOHead
        title="Core-CT - Asa Sprow's Experience"
        description="My experience as a developer on the Core-CT python library."
        url="https://asasprow.com/experience/core-ct/"
      />
      
      <h2>Team Member at Core-CT</h2>
      <p>Aug 2023 - May 2024</p>
      <ul>
        <li>
          Built a Python library to help geology researchers analyze rock, mud, and sediment cores.
        </li>
        <li>
          Worked closely with stakeholders to ensure the library was easy to use, intuitive, and performant.
        </li>
        <li>
          Designed the class/module structure, optimized performance, and wrote documentation.
        </li>
        <li>
          Implemented GitHub Actions for automated testing, linting, building documentation, and publishing to PyPI.
        </li>
      </ul>
      <div className="links">
        <a href="https://github.com/mines-erds/core-ct" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
        <a href="https://core-ct.readthedocs.io/en/latest/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faReadme} size="2x" />
        </a>
        <a href="https://pypi.org/project/core-ct/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faPython} size="2x" />
        </a>
      </div>
    </section>
  );
}

export default CoreCT;
