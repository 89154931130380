import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Experience from './pages/Experience';
import CACI from './pages/experience/CACI';
import Sherpa6 from './pages/experience/Sherpa6';
import CoreCT from './pages/experience/CoreCT';
import Projects from './pages/Projects';
import TurnBasedStrategy from './pages/projects/TurnBasedStrategy';
import MinigameFramework from './pages/projects/MinigameFramework';
import AbilityShooter from './pages/projects/AbilityShooter';
import NotFound from './pages/NotFound';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <Router>
      <div id="root">
        {/* <header>
          <h1>Asa Sprow</h1>
          <p>Highly motivated software engineer with a passion for coding and software design.</p>
        </header> */}
        <Navbar />
        <main>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/experience" element={<Experience />} />
            <Route path="/projects" element={<Projects />} />

            {/* Project Detail Routes */}
            <Route path="/projects/turn-based-strategy" element={<TurnBasedStrategy />} />
            <Route path="/projects/minigame-framework" element={<MinigameFramework />} />
            <Route path="/projects/ability-shooter" element={<AbilityShooter />} />

            {/* Experience Detail Routes */}
            <Route path="/experience/caci" element={<CACI />} />
            <Route path="/experience/sherpa6" element={<Sherpa6 />} />
            <Route path="/experience/core-ct" element={<CoreCT />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
