import React from 'react';
import SEOHead from '../../components/SEOHead';

function TurnBasedStrategy() {
  return (
    <section className="project-container">
      <SEOHead
        title="Turn-Based Multiplayer Strategy Game - Asa Sprow's Projects"
        description="Turn-based multiplayer strategy game with advanced pathfinding, networking, procedural generation, and rendering built in Godot."
        url="https://asasprow.com/projects/ability-shooter/"
      />

      <h2>Multiplayer Turn-based Strategy Game</h2>
      <p>
        This project involved several key components:
      </p>
      <ul>
        <li>
          Built a custom pathfinding implementation to handle complex behaviors based on Dijkstra's algorithm and A* (AStar).
        </li>
        <li>
          Wrote an efficient and highly customizable rendering system that supports procedurally generated graphics.
        </li>
        <li>
          Engineered reliable networking architecture (netcode) to minimize and correct lag and desynchronization.
        </li>
        <li>
          Implemented procedural generation techniques to make each playthrough unique.
        </li>
        <li>
          Designed an intuitive UI to make interfacing with the game’s various systems easy and fun.
        </li>
      </ul>
    </section>
  );
}

export default TurnBasedStrategy;
