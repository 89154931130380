import React from 'react';
import SEOHead from '../components/SEOHead';
import './About.css';

function About() {
  return (
    <section className="about-section">
      <SEOHead
        title="About Me - Asa Sprow"
        description="Dedicated software engineer and game developer passionate about coding, problem-solving, and software design."
        url="https://asasprow.com/about/"
      />

      <h2>About Me</h2>
      <div className="education">
        <h3>Education</h3>
        <p>Bachelor of Science in Computer Science<br/>Colorado School of Mines, Golden, CO<br/>Graduating May 2025<br/>GPA: 3.8</p>
      </div>
      
      <div className="skills">
        <h3>Skills</h3>
        {/* <div className="columns"> */}
          <ul>
            <li>C, C++, Angular, TypeScript, Golang (Go), PostgreSQL (SQL), Git</li>
            <li>Python, Java, Bash, R, GDScript, GDExtension, LaTeX</li>
          </ul>
          <ul>
            <li>Jira, BitBucket, Confluence, Docker, Visual Studio (VS), VS Code</li>
            <li>Godot, Eclipse, GitHub</li>
          {/* </ul>
          <ul> */}
            <li>Docker, GitHub, Linux, Windows 10/11, MacOS</li>
            <li>Technical writing, time management, sprint planning, organization</li>
            <li>Documentation, detail-oriented, problem-solving, fast learner</li>
          </ul>
        {/* </div> */}
      </div>
      
      <div className="hobbies">
        <h3>Hobbies</h3>
        {/* <div className="columns"> */}
          <ul>
            <li>Coding</li>
            <li>Game Development</li>
            <li>Game Dev Club</li>
          {/* </ul>
          <ul> */}
            <li>Gaming (4X, colony builder, management, tycoon genres)</li>
            <li>Climbing</li>
            <li>Skiing</li>
          </ul>
        {/* </div> */}
      </div>
    </section>
  );
}

export default About;
