import React from 'react';
import { Link } from 'react-router-dom';
import { faGithub, faReadme, faPython } from '@fortawesome/free-brands-svg-icons';
import SEOHead from '../components/SEOHead';

const experiences = [
  {
    id: 'caci',
    company: 'CACI International, Inc.',
    position: 'Software Engineering Intern',
    startDate: 'May 2024',
    endDate: 'Present',
    details: [
      'Developed a Java backend/REST API server using Spring, Liquibase, and PostgreSQL',
      'Practiced AGILE methodologies in a SCRUM team',
      'Increased efficiency through OpenAPI code generation to ensure consistency in backend and frontend REST operations',
      'Deployed to a Kubernetes cluster and configured deployment using helm charts',
      'Collaborated across teams to define a cohesive and useful REST API adhering to industry best practices',
      'Automated frontend and backend library builds, updates, and installation using Just scripts and OpenAPI codegen',
      'Shipped a Java library providing easy-to-use logging annotations using AspectJ and Aspect Oriented Programming (AOP)',
      'Automates logging for function calls and REST API endpoints minimizing boiler plate and repeated code',
      'Ensures consistency and quality of logs in critical areas accelerating testing and debugging'
    ],
    links: []
  },
  {
    id: 'sherpa6',
    company: 'Sherpa 6, Inc.',
    position: 'Software Engineering Intern',
    startDate: 'May 2023',
    endDate: 'May 2024',
    details: [
      'Worked in a SCRUM team, participated in sprint planning and customer meetings to ensure product quality',
      'Developed and shipped a full-stack application using Angular, Go, and PostgreSQL',
      'Tested back-end and front-end using Postman and Selenium',
      'Automated PDF parsing and data scraping using Python to save hundreds of worker hours'
    ],
    links: []
  },
  {
    id: 'core-ct',
    company: 'Core-CT',
    position: 'Software Engineer',
    startDate: 'Aug 2023',
    endDate: 'May 2024',
    details: [
      'Built a Python library to help geology researchers analyze rock, mud, and sediment cores',
      'Worked closely with shareholders to ensure the library was easy to use, intuitive, and performant',
      'Designed the class/module structure, optimized performance, and wrote documentation',
      'Implemented GitHub Actions for automated testing, linting, building documentation, and publishing to PyPI'
    ],
    links: [
      { url: 'https://github.com/mines-erds/core-ct', icon: faGithub },
      { url: 'https://core-ct.readthedocs.io/en/latest/', icon: faReadme },
      { url: 'https://pypi.org/project/core-ct/', icon: faPython }
    ]
  }
];

function Experience() {
return (
  <section className="container">
    <SEOHead
      title="Asa Sprow - Experience"
      description="My professional software engineering experience."
      url="https://asasprow.com/experience/"
    />

    <h2>Professional Experience</h2>
    <div className="cards-container">
      {experiences.map((exp) => (
        <Link
          to={`/experience/${exp.id}`}
          key={exp.id}
          className="card"
        >
          <h3>
            {exp.position} at {exp.company}
          </h3>
          <p>
            {exp.startDate} - {exp.endDate}
          </p>
        </Link>
      ))}
    </div>
  </section>
);
}

export default Experience;
