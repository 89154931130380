import React from 'react';
import SEOHead from '../../components/SEOHead';

function MinigameFramework() {
  return (
    <section className="project-container">
      <SEOHead
        title="Mingame Framework - Asa Sprow's Projects"
        description="Multiplayer minigame framework with support for UDP and TCP built in Godot."
        url="https://asasprow.com/projects/minigame-framework/"
      />

      <h2>Extensible Multiplayer Minigame Framework</h2>
      <p>
        This project features a fully modular framework containing a networking layer for easy minigame development:
      </p>
      <ul>
        <li>
          Games can be added and removed with no changes to the framework; each game is fully self-contained.
        </li>
        <li>
          Supports both TCP and UDP networking protocols.
        </li>
        <li>
          Serves as a simple way to distribute and playtest my smaller projects.
        </li>
      </ul>
    </section>
  );
}

export default MinigameFramework;
