import React from 'react';
import SEOHead from '../../components/SEOHead';

function CACI() {
  return (
    <section className="container">
      <SEOHead
        title="CACI International - Asa Sprow's Experience"
        description="My experience as a software engineering intern at CACI."
        url="https://asasprow.com/experience/caci/"
      />

      <h2>Software Engineering Intern at CACI International, Inc.</h2>
      <p>May 2024 - Present</p>
      <ul>
        <li>
          Developed a Java backend/REST API server using Spring, Liquibase, and PostgreSQL
        </li>
        <li>
          Practiced AGILE methodologies in a SCRUM team
        </li>
        <li>
          Increased efficiency through OpenAPI code generation to ensure consistency in backend and frontend REST operations
        </li>
        <li>
          Deployed to a Kubernetes cluster and configured deployment using Helm charts
        </li>
        <li>
          Collaborated across teams to define a cohesive and useful REST API adhering to industry best practices
        </li>
        <li>
          Automated frontend and backend library builds, updates, and installation using Just scripts and OpenAPI codegen
        </li>
        <li>
          Shipped a Java library providing easy-to-use logging annotations using AspectJ and Aspect-Oriented Programming (AOP)
        </li>
        <li>
          Automated logging for function calls and REST API endpoints, minimizing boilerplate and repeated code
        </li>
        <li>
          Ensured consistency and quality of logs in critical areas, accelerating testing and debugging
        </li>
      </ul>
    </section>
  );
}

export default CACI;
