import React from 'react';
import SEOHead from '../../components/SEOHead';

function Sherpa6() {
  return (
    <section className="experience-container">
      <SEOHead
        title="Sherpa 6 - Asa Sprow's Experience"
        description="My experience as a software engineering intern at Sherpa 6."
        url="https://asasprow.com/experience/sherpa6/"
      />

      <h2>Software Engineering Intern at Sherpa 6, Inc.</h2>
      <p>May 2023 - May 2024</p>
      <ul>
        <li>
          Worked in a SCRUM team, participating in sprint planning and customer meetings to ensure product quality.
        </li>
        <li>
          Developed and shipped a full-stack application using Angular, Go, and PostgreSQL.
        </li>
        <li>
          Tested back-end and front-end using Postman and Selenium.
        </li>
        <li>
          Automated PDF parsing and data scraping using Python to save hundreds of worker hours.
        </li>
      </ul>
    </section>
  );
}

export default Sherpa6;
