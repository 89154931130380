import React from 'react';
import SEOHead from '../components/SEOHead';

function Home() {
  return (
    <section>
      <SEOHead
        title="Asa Sprow's Portfolio"
        description="Explore my projects, experience, and learn more about me."
        url="https://asasprow.com/"
      />

      <h2>Welcome</h2>
      <p className="text-center">Hello! I'm Asa Sprow, a software engineer with a passion for coding and problem solving. Learn more by exploring the website!</p>
    </section>
  );
}

export default Home;
