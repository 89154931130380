import React from 'react';
import SEOHead from '../../components/SEOHead';

function AbilityShooter() {
  return (
    <section className="project-container">
    <SEOHead
      title="Ability Shooter - Asa Sprow's Projects"
      description="Fast-paced 2D physics based ability shooter developed in Godot."
      url="https://asasprow.com/projects/ability-shooter/"
    />

      <h2>Multiplayer Physics-Based Ability Shooter</h2>
      <p>
        This project focuses on creating a high level of interaction between abilities and status effects:
      </p>
      <ul>
        <li>
          Designed a system that allows for complex interactions between various abilities and status effects.
        </li>
        <li>
          Employed strong object-oriented programming (OOP) principles for maintainability and to minimize technical debt.
        </li>
      </ul>
    </section>
  );
}

export default AbilityShooter;
