import React from 'react';
import { Link } from 'react-router-dom';
import SEOHead from '../components/SEOHead';

const projects = [
  {
    id: 'turn-based-strategy',
    title: 'Multiplayer Turn-based Strategy Game',
    description: 'Long-term project with complex pathfinding, procedural generation, and efficient networking.',
    details: [
      'Built a custom pathfinding implementation to handle complex behaviors based on Dijkstra + AStar (A*)',
      'Wrote an efficient + highly customizable rendering system that supports procedurally generated graphics',
      'Engineered reliable networking architecture (netcode) to minimize and correct lag + desync',
      'Implemented procedural generation techniques to make each playthrough unique',
      'Designed an intuitive UI to make interfacing with the game’s various systems easy and fun'
    ]
  },
  {
    id: 'minigame-framework',
    title: 'Extensible Multiplayer Minigame Framework',
    description: 'A fully modular framework with easy minigame development and TCP/UDP support.',
    details: [
      'Fully modular framework containing a networking layer for easy minigame development',
      'Games can be added and removed with no changes to the framework, each game is fully self-contained',
      'Supports both TCP and UDP networking protocols',
      'Serves as a simple way to distribute + playtest my smaller projects'
    ]
  },
  {
    id: 'ability-shooter',
    title: 'Multiplayer Physics-Based Ability Shooter',
    description: 'A 2D shooter with a high level of interaction between abilities and status effects with strong OOP principles.',
    details: [
      'Designed a system that allows for a high level of interaction between abilities and status effects',
      'Employed strong object-oriented principles (OOP) for maintainability and to minimize technical debt'
    ]
  }
];

function Projects() {
  return (
    <section className="container">
      <SEOHead
        title="Projects - Asa Sprow"
        description="Explore my personal projects and games."
        url="https://asasprow.com/projects/"
      />
      
      <h2>Projects</h2>
      <div className="cards-container">
        {projects.map((project) => (
          <Link
            to={`/projects/${project.id}`}
            key={project.id}
            className="card"
          >
            <h3>{project.title}</h3>
            <p>{project.description}</p>
          </Link>
        ))}
      </div>
    </section>
  );
}

export default Projects;
